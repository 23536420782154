import styled from "@emotion/styled";
import React from "react";
import { lightbulbO } from "react-icons-kit/fa/lightbulbO";
import { SolidCard } from "../Card";
import Icon from "react-icons-kit";
import { Link } from "gatsby";

const HeadIcon = styled(Icon)`
  position: absolute;
  transform: translate(10px, 30px) scale(4);
  opacity: 0.15;
  pointer-events: none;
  @media (min-width: 425px) {
    transform: translate(-8px, 10px) scale(4);
  }
`;

export const TipLink = styled(Link)`
  color: white;
  text-decoration: underline;
`;

const Tip = (props) => (
  <SolidCard collapsed mb={4}>
    <HeadIcon size={36} icon={lightbulbO} />
    <strong>Tipp vom Makler</strong>
    {props.children}
  </SolidCard>
);

export default Tip;
