import { css } from "@emotion/react";
import { Box, Flex } from "@rebass/grid/emotion";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { Helmet } from "react-helmet";
import { breadcrumbs } from "shared/helpers/schema";

import { LinkButton } from "../../components/Button";
import Card from "../../components/Card";
import Hero from "../../components/Hero";
import Kranicon from "../../components/Kranicon";
import Page from "../../components/Page";
import Tip, { TipLink } from "../../components/Tip";
import ProfileImage from "../../components/ProfileImage";
import Stars from "../../components/Stars";
import Tile from "../../components/Tile";
import Contact from "../../components/Contact";
import PageBlock from "../../components/PageBlock";
import ReferenceImage from "../../components/ReferenceImage";

const avatar = css`
  max-width: 90px;
  border-radius: 50%;
  margin: 0 auto;
`;

const Question = ({ title, children, hasOwnParagraphs }) => (
  <Card mx={[0, 0, -40]} mb={4} collapsed>
    <h3>{title}</h3>
    {hasOwnParagraphs && children}
    {!hasOwnParagraphs && <p style={{ margin: 0 }}>{children}</p>}
  </Card>
);

const ImmobilieVerkaufenThueringen = ({ data }) => (
  <Page
    header={
      <Hero
        fluid={data.sellingImage.fluid}
        icon={<Kranicon icon="immobilien" />}
        title="Immobilie verkaufen — so klappt's"
        intro="Nach dem Immobilienverkauf beginnt ein neuer Lebensabschnitt. Erfahren Sie hier, wie Sie Ihre Immobilie professionell verkaufen und entspannt in die Zukunft blicken können."
      />
    }
  >
    <Helmet
      title="Immobilien in Thüringen professionell verkaufen"
      meta={[
        {
          name: "description",
          content:
            "Verkaufen Sie Ihre Immobilie in Thüringen 🌲 professionell und blicken dabei auf eine glückliche 🍀 Zukunft.",
        },
      ]}
      script={[
        breadcrumbs({
          crumbs: [
            {
              name: "Immobilien in Thüringen professionell verkaufen",
              link: "/immobilie-verkaufen-thueringen/",
              position: 1,
            },
          ],
          siteUrl: data.site.siteMetadata.siteUrl,
          siteTitle: data.site.siteMetadata.title,
        }),
      ]}
    />
    <PageBlock>
      <Flex justifyContent="center">
        <Box width={[1, 1, 2 / 3, 1 / 2]} my={5} px={3}>
          <h2>Mit dem Immobilienverkauf Freiraum für die Zukunft schaffen</h2>
          <p>
            Niemand verkauft sein Haus oder seine Wohnung ohne Grund. Vielmehr
            verbirgt sich hinter jedem Immobilienverkauf eine emotionale
            Geschichte. Vermutlich haben auch Sie eine solche Erfahrung gemacht,
            die Sie dazu bringt, Ihre Immobilie zu verkaufen.
          </p>
          <ul>
            <li>Das jahrelang bewohnte Haus wird im Alter zu groß</li>
            <li>
              Erbfälle sind emotional und hinterlassen einen Berg an Arbeit
            </li>
            <li>
              Durch einen Umzug muss das liebevoll aufgebaute Eigenheim
              aufgegeben werden
            </li>
            <li>
              Streit in der Familie macht den Verkauf des Objektes unvermeidbar
            </li>
          </ul>
          <p>
            Trotz der vorherrschenden Gefühlslage ist es notwendig, den
            Überblick zu behalten. Es stehen vielfältige Aufgaben an, die Sie
            bewältigen müssen. Geben Sie deshalb besonders auf sich acht. Der
            Verkaufsprozess belastet nicht wenige Menschen psychisch und
            physisch enorm. Hier ist die professionelle Unterstützung durch
            einen Makler immer eine Überlegung wert.
          </p>
          <h2>Wichtige Fragen vor dem Verkaufsprozess</h2>
          <p>
            Bevor die eigentliche Arbeit des Verkaufes beginnen kann, müssen Sie
            als Verkäufer zunächst Antworten auf einige wichtige Fragen finden.
            Dabei spielt es keine Rolle, ob es sich bei dem zu verkaufenden
            Objekt um ein Haus, eine Wohnung oder ein Grundstück handelt.
          </p>
          <Question title="Der Verkaufszeitpunkt: Ab wann kann die Immobilie verkauft werden?">
            In den meisten Fällen sind Verkäufer auch Selbstnutzer der
            Immobilie. Planungssicherheit spielt deshalb eine besonders wichtige
            Rolle. Geben Sie sich für Ihren Auszug einen verbindlichen Termin.
            So können Sie strukturiert Ihren eigenen Lebensweg besser planen und
            geben als Verkäufer den Kaufinteressenten ebenfalls die Möglichkeit
            dazu.
          </Question>
          <Question title="Gibt es offene Kredite und Vorfälligkeitsentschädigungen?">
            Durch einen unverhofften Umzug oder familiärer Streitigkeiten ist
            der Hausverkauf manchmal nicht zu vermeiden. In diesen Fällen ist
            der Hauskredit aber meist noch nicht vollständig zurückgezahlt.
            Durch den erzielten Verkaufspreis können zwar die ausstehenden
            Forderungen abgedeckt werden, jedoch können zusätzliche Kosten
            entstehen. Gibt es vielleicht noch Vorfälligkeitsentschädigungen,
            die bei der vorzeitigen Ablösung des Kredites fällig werden? Ein
            zusätzlicher Blick auf den Kreditvertrag lohnt sich immer.
          </Question>
          <Question
            title="Vom Grundbuch bis zum Energieausweis: Habe ich alle Unterlagen?"
            hasOwnParagraphs
          >
            <p>
              Bei einem Immobilienverkauf gibt es verschiedene Dokumente, die
              immer griffbereit sein sollten. Sowohl für Makler als auch für
              Eigentümer, die Ihre Immobilie privat verkaufen möchten, ist die
              gründliche Vorbereitung entscheidend.
            </p>
            <h4>Was sollte da sein?</h4>
            <ul>
              <li>Amtliche Flurkarte</li>
              <li>Grundbuchauszug</li>
              <li>Grundrisse</li>
              <li>Wohnflächenberechnung</li>
              <li>Energieausweis</li>
              <li>Aufstellung über Modernisierungen/Sanierungen</li>
            </ul>
            <p>
              Achten Sie bitte darauf, dass Sie als Eigentümer verpflichtet
              sind, einen Energieausweis vorzulegen. Ausnahmen gibt es beim
              Hausverkauf, wenn das Objekt kleiner als 50 Quadratmetern ist oder
              unter Denkmalschutz steht.
            </p>
            <p>
              Auszüge aus dem Grundbuch oder amtliche Flurkarten müssen zudem
              bei Behörden angefordert werden.
            </p>
            <p>
              Wenn Sie weitere Fragen zu den wichtigsten Unterlagen haben, dann
              stehen wir Ihnen unverbindlich als erfahrene Makler gerne zur
              Verfügung.
            </p>
          </Question>
          <Question title="Habe ich genügend Zeit für den Immobilienverkauf?">
            Hausverkäufer bzw. Verkäufer von Eigentumswohnungen unterschätzen
            meist den Aufwand des Immobilienverkaufes. Das Haus oder die Wohnung
            können zwar schnell im Internet inseriert werden, doch dies stellt
            nur einen kleinen Teil der Arbeit dar. Sie als Eigentümer sollten
            sich schon während der Planung Ihrer Verkaufsstrategie überlegen, ob
            die zu leistenden Aufgaben mit Ihrem Arbeitsalltag vereinbar sind.
          </Question>
          <Question
            title="Kann ich als Verkäufer vor Ort sein?"
            hasOwnParagraphs
          >
            <p>
              Aus der Ferne stellt der Immobilienverkauf eine fast
              unüberwindbare Hürde dar. Eigentümer wissen, dass bei einem Haus
              oder einer Wohnung immer mal wieder Probleme auftreten können, die
              eine unverzügliche Lösung benötigen.
            </p>
            <p>
              Hinzu kommen Besichtigungstermine mit Kaufinteressenten. Hier ist
              festzuhalten, dass der Hausverkauf selten bei der ersten
              Besichtigung direkt erfolgreich verläuft.
            </p>
            <p>
              Wer nun durch einen Umzug oder ein Erbe nicht in der unmittelbaren
              Nähe der Immobilie wohnt, benötigt eine hohe Reisebereitschaft.
              Machen Sie sich schon vor dem eigentlich Verkauf mit diesem
              Problem vertraut. Ein Makler vor Ort ist in diesen Fällen immer
              die beste Option.
            </p>
          </Question>
          <h2>Die 5 Schritte zum Immobilienverkauf</h2>
          <h3>1. Einen Verkaufspreis finden, der passt</h3>
          <p>
            Private Verkäufer haben häufig eine sehr emotionale Verbindung zu
            Ihrem Haus bzw. der Eigentumswohnung. Dort ist man vielleicht
            aufgewachsen, hat evtl. eine Familie gegründet oder einfach schöne
            Zeiten mit seiner Familie verbracht.
          </p>
          <p>
            Dies führt oft dazu, dass der gewählte Verkaufspreis nicht ganz der
            Marktlage entspricht. Das ist nachvollziehbar, da Eigentümer Ihrer
            Immobilie einen ganz anderen Wert beimessen als es der tatsächliche
            Immobilienpreis hergibt. Zudem möchten Eigentümer im besten Fall
            noch Gewinne mit dem Verkauf erzielen.
          </p>
          <p>
            In der Summe führt das in den meisten Fällen dazu, dass der
            festgelegte Angebotspreis entweder viel zu hoch oder manchmal auch
            viel zu niedrig angesetzt wird. Für Käufer spielt der Kaufpreis
            jedoch eine sehr große Rolle. Die Nachfrage wird entsprechend
            geringer, je höher der Preis ist.
          </p>
          <p>
            Um zu vermeiden, dass der aufgerufene Preis den Immobilienverkauf
            verhindert, ist eine seriöse Immobilienbewertung ratsam. Diese
            stützt sich auf harte Fakten und ermöglicht es Verkäufern, einen
            Preis in angemessener Höhe zu finden.
          </p>
          <p>
            Der Wert einer Immobilie oder eines Grundstückes wird von allerlei
            Faktoren beeinflusst. Folgende Fragen muss man sich bei der
            Wertermittlung stellen:
          </p>
          <ul>
            <li>Wie attraktiv ist die Lage?</li>
            <li>In welchem Zustand befindet sich die Immobilie?</li>
            <li>In welchem Jahr wurde das Objekt erbaut?</li>
            <li>Welche Ausstattung ist vorhanden?</li>
            <li>In welchem Bereich liegt der Energiebedarf?</li>
            <li>
              Ist das Umfeld hinsichtlich Infrastruktur und Gesellschaft gut
              aufgestellt?
            </li>
            <li>Wie hoch ist der Bodenrichtwert?</li>
          </ul>
          <p>
            Im besten Fall wird die Wertermittlung Ihrer Immobilie durch einen
            Experten durchgeführt. Für den späteren Verkaufspreis wäre das die
            beste Vorgehensweise. Ein erfahrener Makler kann die
            Immobilienbewertung für Sie übernehmen. Aber auch Sachverständige
            und freie Gutachter sind in der Lage, den Wert Ihrer Immobilie
            richtig einzuschätzen.
          </p>
          <p>
            Der daraus resultierende Wert stellt sodann die perfekte
            Ausgangslage dar, um einen marktgerechten Preis aufzurufen.
            Vergessen Sie aber nicht, Ihrem Preis einen kleinen Spielraum für
            spätere Preisverhandlungen hinzuzufügen.
          </p>
          <Tip>
            <p>
              Die Höhe des Kaufpreises ist ein wichtiger Baustein beim
              Immobilien&shy;verkauf. Mit unserem kostenlosen Tool zur
              Immobilien&shy;bewertung erhalten Sie schnell und einfach eine
              professionelle Bewertung.
            </p>
            <TipLink to="/immobilienbewertung">
              Jetzt zur kostenlosen Immobilienbewertung
            </TipLink>
          </Tip>
          <h3>2. Mit der richtigen Vermarktung Menschen begeistern</h3>
          <p>
            Um Interessenten auf eine Immobilie aufmerksam zu machen, ist eine
            gute Vermarktung nötig. Der Verkauf einer Immobilie ist deshalb
            maßgeblich von der Wahl der richtigen Werbemittel abhängig. Das Ziel
            muss es sein, Kaufinteressenten anzusprechen und für Ihre Immobilie
            zu begeistern. Schon bevor potenzielle Käufer um einen
            Besichtigungstermin bitten, sollte diese schon regelrecht vom Objekt
            gefesselt sein. Auch bei den gewählten Vertriebskanälen ist
            Diversität ein entscheidender Vorteil.
          </p>
          <h4>Kanäle zur Vermarktung</h4>
          <p>
            Nutzen Sie alle Möglichkeiten, Ihre Immobilie zu bewerben. Folgende
            Kanäle können Sie problemlos nutzen:
          </p>
          <ul>
            <li>Immobilienportale im Internet</li>
            <li>Anzeigen in regionalen Zeitungen</li>
            <li>Mundpropaganda durch Freunde & Familie</li>
            <li>Soziale Netzwerke wie Facebook und Instagram</li>
          </ul>
          <p>
            Sollten Sie sich dafür entscheiden, sich beim Verkauf einem Makler
            anzuvertrauen, so profitieren Sie zusätzlich von deren Netzwerken.
            Viele Makler verfügen wie wir über eine rege Liste an Interessenten,
            die auf Immobilien wie die Ihre warten.
          </p>
          <h4>Werbemittel wählen</h4>
          <p>
            Bei der Wahl der Werbemittel profitieren Sie jetzt von Ihrer guten
            Vorbereitung. Nutzen Sie alle zusammengetragenen Unterlagen und
            ergänzen Sie diese mit zusätzlichen Bild- und Videomaterial. Fügen
            Sie nun beides Zusammen und Sie erhalten ein verkaufsförderndes
            Exposé. Dieses Exposé stellt sodann ein wunderbares Werbemittel dar,
            um Interesse für Ihr Objekt zu wecken.
          </p>
          <p>Zusammenfassung:</p>
          <ul>
            <li>Nutzen sie professionelles Bildmaterial</li>
            <li>Vergessen Sie den Energieausweis nicht</li>
            <li>Erstellen Sie verkaufsfördernde Grundrisse</li>
            <li>Benennen Sie alle Fakten wie Preis, Lage usw.</li>
            <li>Fassen Sie alles zu einem Exposé zusammen</li>
          </ul>
          <Tip>
            Achten Sie bitte stets auf die Qualität Ihrer Werbemittel.
            Hochwertige Bilder und speziell angefertigte Exposés erhöhen Ihre
            Erfolgschancen ungemein.
          </Tip>
          <h3>3. Immobilie in Szene setzen</h3>
          <p>
            Sobald es zu Besichtigungen durch Interessenten kommt, ist die
            optimale Präsentation des zu verkaufenden Objektes essenziell. Jede
            Immobilie ist einzigartig und versprüht ihren ganz eigenen Charme.
            Verkäufer sollten diesen Charme unbedingt einfangen und für einen
            erfolgreichen Verkauf zunutze machen. Machen Sie sich auch wegen
            kleinerer "Fehler" keine Sorgen. Der Fokus sollte stets auf die
            schönen Dinge gerichtet werden.
          </p>
          <p>
            Ein Beispiel: Vielleicht ist das Bad nicht mehr ganz zeitgemäß,
            jedoch verspricht der Wintergarten einzigartige Momente mit der
            Familie. Lenken Sie den Fokus bei der Präsentation stets auf die
            Vorteile Ihrer Immobilie.
          </p>
          <p>
            Ganz gleich, ob Hausverkauf oder Wohnungsverkauf, verschaffen Sie
            sich zunächst einen Überblick über alle Vorzüge Ihres Objektes.
            Erfahrene Makler können zwar mit geschultem Blick die schönsten
            Stellen schnell ausfindig machen, aber auch Privatverkäufer sind in
            der Lage, diese zu finden. Versetzen Sie sich dazu in die Lage Ihrer
            Kaufinteressenten und gehen Sie unvoreingenommen auf die Suche.
          </p>
          <h4>Rücken Sie Ihre Immobilie ins rechte Licht:</h4>
          <ol>
            <li>
              Konzentrieren Sie sich auf die Stärken Ihrer Immobilie und des
              Umlandes
            </li>
            <li>Schaffen Sie eine angenehme und wohlige Atmosphäre</li>
            <li>Beflügeln Sie die Fantasie der Kaufinteressenten</li>
          </ol>
          <Tip>
            <div>
              Mit Homestaging sind Verkäufer in der Lage, Ihr Haus oder Ihre
              Wohnungen optimal zu präsentieren. Was das genau ist,{" "}
              <TipLink to="/homestaging">erfahren Sie hier</TipLink>.
            </div>
          </Tip>
          <h3>4. Vom Interessenten zum Käufer</h3>
          <p>
            Sie oder Ihr Immobilienmakler haben bei der Vermarktung ganze Arbeit
            geleistet. Als Belohnung häufen sich Anfragen von potenziellen
            Käufern.
          </p>
          <p>
            Auch wenn das schon ein kleiner Erfolg ist, so sollte man nicht mit
            jedem Kaufinteressenten direkt eine Besichtigung vereinbaren.
          </p>
          <p>
            Viele Eigentümer haben den Wunsch Ihre Immobilie in gute Hände
            abzugeben. Vielleicht besteht der Wunsch, das Haus der Eltern an
            eine junge Familie zu verkaufen. Oder die Eigentumswohnung soll doch
            lieber an einen Single verkauft werden, als an eine Großfamilie.
          </p>
          <p>
            Diese Kriterien sollten bereits im Vorfeld definiert werden. Ein
            weiterer Punkt, der zum Ausschluss eines Interessenten führen kann,
            ist die finanzielle Situation. Die Bonitätsprüfung des potenziellen
            Käufers ist beim Immobilienverkauf elementar und sollte detailliert
            und unter größtmöglicher Achtsamkeit durchgeführt werden.
          </p>
          <p>
            Wurden passende Käufer gefunden, so können die Besichtigungen
            erfolgen. Hier ist Organisationsgeschick gefragt, um effizient die
            Bedürfnisse aller Beteiligten zu befriedigen.
          </p>
          <p>
            Mit viel Liebe zum Detail und gut vorbereitet wird die Immobilie
            präsentiert und auf die Fragen der Interessenten eingegangen.
            Empathie und Professionalität ist bei der Besichtigung mit
            Interessenten das A und O. Ohne Einfühlungsvermögen und Erfahrung
            kann aus dem Besichtigungstermin sonst schnell ein Reinfall werden.
          </p>
          <h3>5. Vertrag und Notar</h3>
          <p>
            Der letzte Schritt des Immobilienverkaufes ist der Gang zum Notar.
          </p>
          <p>
            Haben Sie oder Ihr Immobilienmakler den perfekten Käufer für Ihre
            Immobilie gefunden, so muss ein Notartermin vereinbart werden. Eine
            notarielle Beurkundung ist in Deutschland verpflichtend. Fehlt
            diese, ist der geschlossene Vertrag nicht rechtskräftig.
          </p>
          <p>
            Der Käufer wählt im Normalfall den Notar aus. Ebenfalls ist der
            Käufer für die anfallenden Kosten verantwortlich. Als Verkäufer
            haben Sie jedoch die Aufgabe, alle notwendigen Unterlagen zur
            Verfügung zu stellen.
          </p>
          <p>
            Legen Sie beim Notartermin besonderes Augenmerk auf einzelne
            Vertragsklauseln. Nebenabsprachen, Gewährleistungen und
            Grundschuldvereinbarungen sollten Sie stets im Blick haben.
          </p>
          <p>
            Haben Sie einen guten Makler an Ihrer Seite, so wird dieser sich
            auch um den letzten Schritt aufopferungsvoll kümmern.
          </p>
        </Box>
      </Flex>
      <Flex justifyContent="center">
        <Box width={[1, null, 3 / 4]}>
          <Card>
            <Flex alignItems="center" flexWrap="wrap">
              <Box width={[1, 1, 1, 1 / 3]} pr={[0, 0, 0, 4]} mt="-20px">
                <ProfileImage />
              </Box>
              <Box width={[1, 1, 1, 2 / 3]} px={3}>
                <h2>Diesen Weg müssen Sie nicht alleine gehen</h2>
                <p>
                  Der Immobilienverkauf ist ein Kraftakt. Diese Detail-basierte
                  Arbeit ist nur selten zu meistern, wenn der Kopf eigentlich
                  ganz woanders ist. Als Immobilienmakler stehe ich{" "}
                  <strong>vertrauensvoll an Ihrer Seite</strong> und übernehme
                  den Großteil Ihrer Arbeit.
                </p>
                <ul
                  css={css`
                    list-style-type: "✓  ";
                  `}
                >
                  <li>
                    Kompetente und professionelle{" "}
                    <strong>Rund­um­be­treu­ung</strong>
                  </li>
                  <li>
                    Detaillierte und <strong>transparente Arbeitsweise</strong>
                  </li>
                  <li>
                    Ehrlicher und <strong>freundlicher Umgang</strong>
                  </li>
                  <li>
                    <strong>Flexible Lösungen</strong> individueller Probleme
                  </li>
                </ul>
                <LinkButton to="/kontakt/">
                  Sprechen Sie mit mir über Ihre Immobilie
                </LinkButton>
              </Box>
            </Flex>
          </Card>
        </Box>
      </Flex>
      <Flex justifyContent="center">
        <Box my={6} px={3} textAlign="center">
          <h2>
            Die Qualität eines Maklers zeigt sich in den Meinungen seiner Kunden
          </h2>
          <Flex flexWrap="wrap" mx={-3} mt={4}>
            {data.testimonials.edges.map((testimonial, index) => (
              <Box width={[1, 1, 1 / 3]} my={3} px={4} key={index}>
                <Box mb={3}>
                  {testimonial.node.frontmatter.image ? (
                    <Img
                      css={avatar}
                      fluid={
                        testimonial.node.frontmatter.image.childImageSharp.fluid
                      }
                    />
                  ) : (
                    <Kranicon css={avatar} colored icon="kunde" />
                  )}
                </Box>
                <h4 style={{ margin: 0 }}>
                  {testimonial.node.frontmatter.author}
                </h4>
                <Box fontSize={1} opacity={0.5}>
                  {testimonial.node.frontmatter.source}
                </Box>
                <Stars />
                <div
                  style={{ textAlign: "left" }}
                  dangerouslySetInnerHTML={{ __html: testimonial.node.html }}
                />
              </Box>
            ))}
          </Flex>
          <Box margin="0 auto" maxWidth="200px">
            <hr />
          </Box>
          <Box textAlign="center">
            Über 35 weitere fantastische Kundenbewertungen auf{" "}
            <a
              href="https://g.page/kranichimmobilien"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google ansehen
            </a>
            .
          </Box>
        </Box>
      </Flex>

      <Flex
        flexWrap="wrap"
        px={3}
        justifyContent="center"
        width={[1, null, 3 / 4]}
        margin="0 auto"
      >
        <h2>Projekte, die zuletzt von mir abgeschlossen wurden</h2>
        {data.references.edges.map((reference, i) => (
          <Card my={3} key={i}>
            <Flex flexWrap="wrap" alignItems="center">
              <Box width={[1, 2 / 5]} mb={[4, 0]}>
                <ReferenceImage
                  fluid={reference.node.frontmatter.image.childImageSharp.fluid}
                />
              </Box>
              <Box width={[1, 3 / 5]} pl={[0, 4]}>
                <h3>
                  {reference.node.frontmatter.location} ·{" "}
                  {reference.node.frontmatter.type}
                </h3>
                <div
                  dangerouslySetInnerHTML={{ __html: reference.node.html }}
                />
              </Box>
            </Flex>
          </Card>
        ))}
        <LinkButton to="/immobilie-verkaufen-thueringen/referenzen">
          Alle Referenzen ansehen
        </LinkButton>
      </Flex>
      <Tile>
        <Flex justifyContent="center" mt={4}>
          <Box width={[1, null, 1 / 2]}>
            <Card red>
              <ProfileImage />
              <Box mt={3}>
                <h3>Herausforderungen gemeinsam angehen</h3>
                <p>
                  Möchten Sie mit mir über Ihre Immobilie sprechen? Füllen Sie
                  dafür bitte das Formular aus. Ich melde mich danach so schnell
                  wie möglich bei Ihnen. Einfach, schnell und unverbindlich.
                </p>
                <Contact />
              </Box>
            </Card>
          </Box>
        </Flex>
      </Tile>
    </PageBlock>
  </Page>
);

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    sellingImage: imageSharp(fluid: { originalName: { regex: "/selling/" } }) {
      fluid(maxWidth: 1600, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    references: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/src/references/" } }
      sort: { fields: [frontmatter___order] }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            location
            type
            order
          }
          html
        }
      }
    }
    testimonials: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/src/testimonials/" } }
      sort: { fields: [fileAbsolutePath] }
    ) {
      edges {
        node {
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 250, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            author
            source
          }
          html
        }
      }
    }
  }
`;

export default ImmobilieVerkaufenThueringen;
