import React from "react";
import { css } from "@emotion/react";
import Icon from "react-icons-kit";
import { star } from "react-icons-kit/fa/star";

const Stars = ({ dense }) => {
  let stars = [];
  const margin = dense ? "0 1px" : "10px 4px";
  const size = dense ? "16" : "22";
  for (var i = 0; i < 5; i++) {
    stars.push(
      <Icon
        css={css`
          margin: ${margin};
          color: gold;
        `}
        size={size}
        icon={star}
        key={i}
      />
    );
  }
  return <div>{stars}</div>;
};

export const Star = ({ dense }) => {
  const margin = dense ? "0 1px" : "10px 4px";
  const size = dense ? "16" : "22";
  return (
    <Icon
      css={css`
        margin: ${margin};
        color: gold;
      `}
      size={size}
      icon={star}
    />
  );
};

export default Stars;
